import { Outlet, createBrowserRouter } from "react-router-dom";
import Home from "pages/Home";

function Layout() {
  return <Outlet />;
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "login",
        lazy: () => import("pages/Login"),
      },
      // {
      //   path: "*",
      //   element: <Navigate to="/login" replace={true} />,
      // },
    ],
  },
]);
