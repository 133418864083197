import React from "react";
function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

export default Home;
